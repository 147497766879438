import React from "react";
import { buildFluidImageData } from "@imgix/gatsby";

import { Box, Container, Title, Section, Subtitle } from "tailwind-react-ui";

// Image imports
import TriangleGY from "../images/patterns/patterns-triangles_greenyellow.svg";
import DotsGreen from "../images/patterns/patterns-dots_green.svg";

const SubPageBanner = (props) => {
  const image = props.image;
  const padding = props.padding ?? "py-40";

  const imageFormatted = buildFluidImageData(`${image.url}`, {
    ar: 21 / 9, // required
    auto: ["format", "compress"],
    sizes: `2000px, 1440px, 1280px, 900px, 640px, 400px`, // optional, but highly recommended
    width: 2100,
    height: 900,
  });

  return (
    <>
      <Section
        className="flex flex-center justify-center text-center relative flex-col bg-gray-200 p-0"
        p="0"
        bg="gray-200"
        data-component-type="sub-page-banner"
        style={{
          minHeight: "400px",
          backgroundImage: `url(${imageFormatted.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: image.focalPoint
            ? `${image.focalPoint[1] * 100}%`
            : "center",
          backgroundPositionX: image.focalPoint
            ? `${image.focalPoint[0] * 100}%`
            : "center",
          ar: 3,
        }}
      >
        <div className="bg-gradient-to-b from-black to-transparent absolute top-0 left-0 w-full h-full opacity-20"></div>
        <Container className={`py-10 lg:${padding} relative`}>
          <TriangleGY
            className="absolute left-0 top-full -mt-5"
            alt="Triangle Green Yellow Pattern"
          />
          <Box m="auto" className="m-auto lg:w-5/6">
            <Subtitle
              level={4}
              size={1}
              m="3"
              className="font-heading m-3 text-white text-sm lg:text-lg text-shadow-lg pb-1 break-all"
              font="heading"
            >
              {props.subtitle}
            </Subtitle>
            <Title
              level={1}
              text="white"
              font="heading"
              className={`text-white font-heading text-3xl leading-none text-shadow-lg ${
                props.title.length < 20
                  ? "text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
                  : "md:text-5xl"
              } break-all`}
            >
              {props.title}
            </Title>
          </Box>
        </Container>
        <DotsGreen
          className="hidden md:block absolute right-10 top-full -mt-20"
          alt="Dots Green Pattern"
        />
      </Section>
    </>
  );
};

export default SubPageBanner;
