import React from "react"
import { 
	graphql, 
	// Link 
} from "gatsby"

// Layout
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Matrix from "../components/Matrix"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { 
	Container,
	Section
} from 'tailwind-react-ui'

// Custom Components
import SubPageBanner from '../components/SubPageBanner'
import SecondaryNav from '../components/SecondaryNav'

// GraphQL
export const query = graphql`
	query ComeExplorePageAutoQuery($slug: String!) {
		entry: craftComeExplorePagesComeExplorePagesEntry(slug: { eq: $slug }) {
			id
			uri
			url
			title
			subtitle
			postDate
			remoteId
			typeHandle
			featureImage { ...UploadAssetFragment }
			metaTitle
			metaDescription
			metaKeywords
			metaImage { ...UploadAssetFragment }
			bodyContent {
				... BodyContentFragment
			}
			slug
		}
		siteInfo: craftSiteInformationGlobalSet {
			...SiteInfoFragment
		}
		navigation: allCraftMainNavigationRelatedEntryEntry(filter: {relatedEntry: {elemMatch: {sectionId: {eq: 19}}}}) {
            group(field: relatedEntry___sectionId) {
                nodes {
                    relatedEntry {
                        title
                        uri
                    }
                }
            }
        }
	}
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const ComeExplorePage = ({ data: { entry, siteInfo, navigation } }) => {
	
	// secondary navigation
	const secondaryNav = navigation.group[0].nodes

	const subtitle = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>

			<Section p="0" className="relative">
				<SubPageBanner
					title={entry.title}
					subtitle={subtitle}
					image={featureImage}
				/>
			</Section>

			<SecondaryNav items={secondaryNav} />

			{entry.bodyContent.length ? <Section p="0" className="secondary-page">
				<Container className="xl:w-2/3 pb-18 pt-16">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }
		</Layout>
	)
}

export default ComeExplorePage